@import "../config/variable.module.scss";
.googlemap-icon div[style*="z-index: 3; position: absolute; height: 100%; width: 100%; padding: 0px; border-width: 0px; margin: 0px; left: 0px; top: 0px; touch-action: pan-x pan-y;"] {
    display: none;
}
.gm-style .gm-style-iw-tc::after {
    background: $lightgreen !important;
    top: -2px;
    width: 25px;
}
.gm-style .gm-style-iw-d {
    overflow: auto !important;
}
.gm-style .gm-style-iw-c {
    background-color: $lightgreen !important;
    padding: 12px !important;
}
.laneoverviewMap{
    .gm-style{
        .gm-style-iw-c {
            background-color: $white !important;
        }
        .gm-style-iw-tc{
            &::after {
                background: $white !important;
            }
        }
        .gm-iw{
            color: $dark
        }
    }
}