@import "../config/variable.module.scss";

.carrier-management-screen {
    @include mainSectionBg;
    .search-icon-img{
        span{
            img {
                position: absolute;
                z-index: 9;
                left: 8px;
                top: 31%;
                padding-right: 0px !important;
            }
        } 
        .form-control{
            padding-left: 32px;
            height: 45px;
        }
    } 

    .carrier-management-list {
        .static-table {
            @media (max-width: 1200px) {
                overflow-x: auto;
            }
           
            .form-check{
                min-height: inherit;
                .form-check-input{
                    width: 1.25em;
                    height: 1.25em;
                }
            }
          
            table {
                @media (max-width: 1200px) {
                    max-width: 1200px;
                    width: 1000px;
                }
                tr:last-child:not(:first-child),
                tr:nth-last-child(2):not(:first-child),
                tr:nth-last-child(3):not(:first-child) {
                    td {
                        .action-btnlist {
                            bottom: 0px;
                        }
                    }
                }
                th,
                td {
                    padding: 1rem;
            
                    &:first-child {
                        width: 430px !important;
            
                        @media(max-width:1550px) {
                            width: 283px !important;
                        }
                    }
                    &:nth-child(2){
                        width: 98px;
                    }
                    &:nth-child(3){
                        width: 255px;
                    }
                    &:nth-child(4){
                        width: 190px;
                    }
                    &:nth-child(5){
                        width: 137px;
                    }
                    word-wrap: break-word;
            
                    &:last-child {
                        width: 79px !important;
                    }
            
                    .active {
                        @include circle(10px);
                        background-color: $success;
                    }
            
                    .deactive {
                        @include circle(10px);
                        background-color: $orange;
                    }
                }
                td{
                    
                   h6{
                    font-size: 1rem;
                    @media(max-width:1550px){
                        font-size: 0.875rem;
                    }
                   }
                    .form-check{
                        min-height: inherit;
                        .form-check-input{
                            width: 1.1em;
                            height: 1.1em;
                        }
                    }
                }
                .carrier-logo-wrapper {
                    .logo-icon-name-wrapper{
                        min-width: 55px !important;
                        max-width: 55px !important;
                        padding: 0px !important;
                        @include dFlex;
                        .logo-icon-name{
                            margin-left: 0px !important;
                        }
                    }
                    img {
                        max-width: 55px;
                        min-width: 55px;
                        height: 38px;
                        object-fit: contain;
                    }
                }
               
            }
        }
    }
}