@import "../config/variable.module.scss";

.dataManagement-screen {
    .line {
        width: 1px;
        background: $gray-300;
        height: 40px;
        margin-right: 12px;
    }

    .upload-file-sec {
        .btn-deepgreen {
            @media(max-width:1550px){
                height: 40px !important;
            }
           
            gap: 6px !important;
            @include dFlex;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(152deg) brightness(106%) contrast(101%);
                padding-right: 0px !important;
            }

            &:hover {
                img {
                    filter: brightness(0.5);
                    padding-right: 0px !important;
                }
            }
        }
    }

    .dataManagementUser {
        min-height: calc(100vh - 206px);
        @include mainSectionBg;



        .dataManagementInnerCard {
            .createUserCard {
                @include dFlex;
                flex-direction: column;
                padding: 70px 20px;

                h6 {
                    color: $gray-600;
                }

                @media (max-width:$xxl) {
                    padding: 50px 20px;
                }

                @media (max-width:$xl) {
                    padding: 35px 20px;
                }
            }

            .instruction-wrapper {
                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .mainGrayCards {
                &.borderCard {
                    border: 2px dotted $primary;
                    cursor: pointer;
                    background-color: $gray-100;
                    height: 390px;
                }

                &.borderCard.active {
                    border: 2px dashed $primary;
                    background: $lightgrey;
                }
            }
        }

        .deletedFiles-table {

            th,
            td {
                padding: 1rem !important;

                &:first-child {
                    padding-left: 2.5rem !important;
                    width: 320px;
                    word-break: break-all;
                }

                &:last-child {
                    width: 186px !important;

                    @media(max-width:1270px) {
                        width: 140px !important;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        .dangerbtn-outline {
                            padding: 7px 11px;

                            img {
                                filter: brightness(0) saturate(100%) invert(90%) sepia(29%) saturate(6218%) hue-rotate(312deg) brightness(92%) contrast(82%);
                            }
                        }
                    }

                    &:nth-child(odd) {
                        background-color: $gray-200;
                    }
                }
            }
        }
    }

    .breadCrumbs {
        width: max-content;
        cursor: pointer;

        .default {
            color: $gray-600;
        }

        span {
            padding-top: 3px;
        }

        .primary {
            color: $primary;
            font-weight: 600
        }
    }

    .dataManagement-Wraper {
        @include mainSectionBg;

        .checkboxOuter {
            padding: 5px 5px 0px 5px;
        }

        .dataManagement_List {
            .select-box {

                .custom-select .css-13cymwt-control,
                .css-t3ipsp-control {
                    @media(max-width:1550px) {
                        min-height: 40px;
                    }
                }
            }

            .toggle-action {
                .dropdown-toggle {
                    background: transparent;
                    border: none;
                    padding: 0px;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-divider {
                    border: none;
                    margin: 3px;
                }

                ul {
                    padding-left: 24px;
                }

                .action-btnlist {
                    .dot {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $black;
                    }

                    width: 370px;
                    transform: translate3d(-364.6px, 44.8px, 0px);
                }

                .blue-btn {
                    img {
                        margin-bottom: 3px !important;
                    }
                }
            }

            .deleteBtn-sec {
                .disabled-button {
                    display: none;
                }
            }

            .bottomline {
                border-bottom: 1px solid $gray-200;

                h4 {
                    margin-bottom: 0px;
                }
            }

            .firstRow.selectFolder {
                background-color: $lightgreen !important;
            }

            .static-table {

                th,
                td {
                    padding: 14px 10px !important;

                    &:nth-child(3) {
                        min-width: 142px;
                    }

                    &:nth-child(5) {
                        min-width: 182px;
                    }

                    &:last-child {
                        width: 84px;
                        text-align: end;
                        padding-right: 20px !important;
                    }

                    &:first-child {
                        width: 310px;

                        @media (max-width:1430px) {
                            width: 260px;
                        }

                        @media (max-width:1240px) {
                            width: 240px;
                        }
                    }
                }

                td {
                    h6 {
                        font-size: 1rem;

                        @media(max-width:1550px) {
                            font-size: 0.875rem;
                        }
                    }

                    .file-name {
                        width: 262px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .tootltip-div {
                            display: none;
                        }

                        @media (max-width:1430px) {
                            width: 187px;
                        }

                        @media (max-width:1240px) {
                            width: 170px;
                        }

                        &:hover {
                            .tootltip-div {
                                position: absolute;
                                background-color: $white;
                                padding: 6px;
                                box-shadow: 0 0 10px 0 #00000014;
                                max-width: 258px;
                                font-size: 12px;
                                white-space: normal;
                                display: block;
                                z-index: 9;
                            }
                        }
                    }

                    .owner-name {
                        width: 216px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media(max-width: 1530px) {
                            width: 157px;
                        }
                    }

                    padding-right: 10px;

                    .errorMsg {
                        @include mainSectionBg;
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
                        width: 161px;
                        padding: 10px;
                        position: absolute;
                        z-index: 5;
                        top: 59px;
                        right: 14px;
                        display: none;

                        &.showErrorMsg {
                            display: block;
                        }
                    }



                    .action-btn-dropdown {
                        .dropdown-toggle {
                            background-color: transparent;
                            border: none;
                            padding: 0px;

                            &::after {
                                display: none;
                            }

                            &.bg_circle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                background-color: transparent;
                            }

                            &.bgAction_circle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                background-color: $gray-300;
                            }
                        }
                    }

                    .action-btnlist {
                        position: absolute;
                        right: 19px;
                        width: 190px;
                        background: $white;
                        padding: 12px;
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
                        border-radius: 4px;
                        z-index: 5;
                        display: none;

                        button {
                            padding: 8px 8px;
                        }

                        &.action-btnlistVisible {
                            display: flex;
                        }

                        .gray-btn {
                            &:hover {
                                background: $gray-300 !important;
                            }
                        }
                    }

                }

                tr:last-child:not(:first-child),
                tr:nth-last-child(2):not(:first-child),
                tr:nth-last-child(3):not(:first-child) {
                    td {
                        .action-btnlist {
                            bottom: 0px;
                        }
                    }
                }
            }
        }
    }

}

.dataManagement.modal-dialog {
    height: 100vh;
    --bs-modal-width: 500px !important;

    .modal-content {
        padding: 16px 16px 16px 20px;

        .modal-header {
            .modal-title {
                font-size: 20px;
                font-weight: 600;
            }

            border-bottom: none;
        }

        .modal-body {
            .form-label {
                font-size: 16px;
            }

            .form-select {
                padding: 10px;
            }

            .form-control {
                padding: 10px 10px;

                &:focus {
                    border-color: $gray-500;
                    outline: 0;
                    box-shadow: none;
                }
            }

            .disabled-button {
                width: 100% !important;
                padding: 11px 15px !important;
            }
        }

        .gray-btn {
            background-color: $gray-200 !important;
        }
    }
}

.dataElement.modal-dialog {
    height: 100vh;
    --bs-modal-width: 660px !important;

    .modal-title {
        font-size: 0.875rem;
        font-weight: 600;
        color: $primary;
    }

    .modal-body {
        ul {
            padding-left: 20px;
        }
    }
}

// activity modal css
.dataManagement-Activity.modal-dialog {
    height: 100vh;
    --bs-modal-width: 560px !important;

    .modal-content {
        .modal-header {
            padding: 16px 16px 16px 20px;

            .modal-title {
                font-size: 22px;
                font-weight: 600;
            }
        }

        .activityBody {
            padding: 0px 20px 25px;
            min-height: 307px;
            height: 307px;
            overflow-y: auto;

            .firstRow {
                border-bottom: 1px solid $gray-200;
                padding-bottom: 14px;
                margin-bottom: 14px;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                }

                h5 {
                    color: $gray-700;
                }

                h6 {
                    color: $gray-600;
                }
            }
        }

        .gray-btn {
            background-color: $gray-200 !important;
        }
    }
}


// move to folder modal css
.dataManagement-folder.modal-dialog {
    --bs-modal-width: 518px !important;
    height: 100vh;

    .modal-content {
        .modal-header {
            padding: 16px 16px 16px 20px;

            .modal-title {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .activityBody {
            .data-height {
                max-height: 166px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            h4 {
                padding: 0px 20px 0px;
            }

            .firstRow {
                padding: 10px 20px 10px;
                background-color: transparent;

                .arrow {
                    display: none;
                }

                &:hover {
                    background-color: $gray-200;

                    .arrow {
                        display: block;
                    }
                }

                &.selectFolder {
                    background-color: $lightgreen;

                    .arrow {
                        display: block;
                    }
                }
            }
        }
    }
}

.dataManagement-folderSuccessful.modal-dialog {
    --bs-modal-width: 480px !important;
    height: 100vh;

    .modal-content {
        .modal-header {
            padding: 16px 16px 16px 20px;
        }

        .activityBody {
            h4 {
                padding: 0px 20px 0px;
                color: $primary;
            }
        }
    }
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000000 !important;
    --bs-backdrop-opacity: 0.6;
    background-color: var(--bs-backdrop-bg);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--bs-backdrop-zindex);
}