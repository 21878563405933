@import "../config/variable.module.scss";

.mainDashboard {
    background: $gray-200;
    display: flex;

    // top heading css
    .topHeading {
        display: none !important;

        h1 {
            color: $white;
            font-weight: 500;
        }

        background: $primary;

        img {
            width: 20px;
        }

        .logoutBtn {
            white-space: nowrap;
        }
    }

    .mainTopTitles {
        h1 {
            font-size: 1.75rem;

            @media(max-width:1600px) {
                font-size: 1.62rem;
            }

            @media(max-width:$xxl) {
                font-size: 1.25rem;
            }

            @media(max-width:$xl) {
                font-size: 1.12rem;
            }
        }
    }

    .mainsidebar.closeSidebar+.DashboardWrapper {
        .dashboard_Header {
            @media (max-width:1250px) {
                width: calc(100% - 0px);
            }
        }
    }

    .mainsidebar+.DashboardWrapper {
        .dashboard_Header {
            @media (max-width:$md) {
                width: calc(100% - 0px);
            }
        }
    }

    .navbar-collapse {
        position: relative;

        .close-btn-sidebar {
            display: none;
            background-color: transparent;

            @media (max-width:1250px) {
                display: block;
                width: 20px;
            }
        }
    }


    .dashboard_Header {
        width: calc(100% - 250px);
        padding: 12px 27px 12px 20px;
        position: fixed;
        background-color: $gray-200;
        z-index: 4;

        @media (max-width: $xxl) {
            width: calc(100% - 190px);
            padding: 8px 29px 8px 17px;
        }
        .logo-icon-name {
            width: 77px;
            height: 69px;
            font-size: 1.625rem;
            font-weight: 700;
            color: $primary;
            margin-left: 0px !important;
            justify-content: flex-start !important;
            background-color: transparent !important;
        }
        .lower-logo{
            img{
                width: 80px;
                height: 65px;
            }
        }
        .searchIcon {
            position: absolute;
            top: 12px;
            left: 15px;
            width: 15px;
        }

        .lower-logo {
            .logo-icon-name-wrapper {
                padding: 0 !important;

                img {
                    width: 134px;
                    height: 65px;
                    object-fit: contain;

                    @media screen and (max-width: $xxl ) {
                        height: 58px;
                        width: 90px;
                    }
                }
            }
        }

        .headerimg {
            img {
                max-width: 50px;
                max-height: 50px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                padding-right: 0px !important;
            }
        }

        .dropdown-toggle {
            border: 1px solid $green;
            padding: 10px;
            position: relative;
            background: transparent;

            .rounded-circle {
                background-color: $green;
            }

            @media(max-width:$sm) {
                padding: 5px;
            }

            &:after {
                display: none;
            }

        }

        .action-btnlist {
            width: 380px;
            background-color: $white;
            box-shadow: 0 0.25rem 0.625rem rgba(20, 20, 20, 0.1);
            height: 385px;
            border-radius: 8px;
            position: absolute;
            inset: 0px 0px auto auto;
            margin: 0px;
            transform: translate3d(6px, 58.6px, 0px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 16px;

            .graph-loader {
                height: 40vh;
            }

            .pre-wrap {
                white-space: pre-wrap;
            }

            .dropdown-item {
                &:active {
                    background-color: transparent !important;
                    color: unset !important;
                }
            }

            .lineBottom {
                border-bottom: 1px solid $gray-200;
                padding: 12px 5px;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px;
                    margin-bottom: 0px !important;
                }
            }

            .gray-btn {
                &:hover {
                    background: $gray-300 !important;
                }
            }
        }

        .noNotification {
            height: 282px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .switch.btn {
            min-width: 149px;
            border-radius: 30px;

            .switch-handle {
                height: 26px;
                margin-top: 5px;
                border-radius: 76px;
                margin-right: 0px;
                margin-left: 37px;
            }

            .switch-on.btn.btn-primary,
            .switch-off.btn.btn-light {
                background-color: $primary;
                border: 1px solid $primary;
                color: $white;
                font-size: 0.87rem;
            }

            .switch-on.btn {
                padding-right: 2.3rem;
            }
        }

        .switch.btn.on.btn-primary {
            background-color: $primary;
            border: 1px solid $primary;

            .switch-handle {
                margin-right: 34px;
                margin-left: 0px;
            }
        }

        .switch.btn.off.btn-light {
            .switch-handle {
                margin-right: 0px;
                margin-left: 37px;
            }
        }

        .userHead_details {
            position: relative;

            p {
                color: $gray-600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;
            }

            .settingProfile_dropdown {
                width: 175px;
                min-height: 100px;
                background-color: $white;
                border-radius: 8px;
                position: absolute;
                right: 0px;
                top: 57px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
                padding: 12px;
                display: none !important;

                .btn-deepgreen {
                    font-size: 0.87rem !important;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }

                .btn-gray {
                    background-color: $gray-100;
                }

                button {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .toogleSwitch {
            .toggle {
                width: 145px !important;
                height: 40px !important;
                border-radius: 36px;
            }

            .toggle.btn {
                .toggle-group {
                    top: 1px;

                    .toggle-handle {
                        height: 26px;
                        margin-top: 5px;
                        border-radius: 76px;
                        margin-left: 43px;
                    }
                }
            }

            .toggle>.toggle-group>.toggle-on,
            .toggle-off {
                font-size: 0.87rem;
                top: 2px;
            }

            .toggle.btn.btn-primary {
                background-color: $primary !important;
                border: 1px solid $primary;

                .toggle-group {
                    .btn-primary {
                        background-color: $primary !important;
                    }

                    .toggle-handle {
                        height: 26px;
                        margin-top: 5px;
                        border-radius: 76px;
                        margin-right: 43px;
                        margin-left: 0px;
                    }
                }
            }

        }

        .searchBar {
            .form-control {
                width: 270px;
                padding: 7px 9px 7px 36px;

                @media (max-width: $xxl) {
                    width: 265px;
                }

                &:focus {
                    color: var(--bs-body-color);
                    background-color: var(--bs-body-bg);
                    border-color: $gray-600;
                    box-shadow: none;
                }
            }
        }
    }

    .rightTabs {
        gap: 13px;

        @media(min-width:$xxl) {
            gap: 13px;
        }
    }

    .verticleLine {
        height: 65px;
        width: 1px;
        background-color: $lightSilver;
    }
}