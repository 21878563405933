@import "../config/variable.module.scss";

.login {
    position: relative;
    .faq-icon{
        position: absolute;
        top: 15px;
        right: 27px;
    }
    // login left side banner css starts
    .left-side-wrapper {
        background: $primary;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: 100%;

        @media (max-width: $md) {
            height: auto;
        }
        .greensight-logo{
            width: 30%;
        }
        .powereb-by {
            @media(max-width:1600px) {
                width: 120px;
            }

            @media(max-width:1300px) {
                width: 100px;
            }
        }
        .pepsico {
            @media(max-width:1600px) {
                width: 65px;
            }

            @media(max-width:1300px) {
                width: 55px;
            }
        }

        .login-map>div:first-child {

            svg {
                @media (max-width: $md) {
                    width: 30% !important;
                }

            }
        }

        .login-map {
            min-height: calc(100vh - 102px);
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media (max-width: $md) {
                height: auto;
                padding: 8px 0;
            }

            .login-heading {
                line-height: normal;
                color: $white;
                font-family: $fontPoppins;

                @media (max-width: $xxl) {
                    line-height: normal;
                }

                @media (max-width: $md) {
                    font-size: 20px !important;
                }
            }
        }
    }

    .left-side-wrapper>div:first-child {
        padding: 20px !important;
        padding-bottom: 0px !important;
    }

    // login left side banner css ends

    // right side form css starts
    .right-side-wrapper {
        width: 75%;

        @media (max-width: $md) {
            width: 100%;
        }
        

        .highlightText {
            color: $primary;
        }

        .form-check-input {
            width: 1.7em;
            height: 1.7em;

            &:checked {
                width: 1.7em;
                height: 1.7em;
            }
        }

        .sign-Login {
            color: $primary;
            border: transparent;
            background-color: transparent;
        }

        .btn-transparent {
            color: $primary;
        }

        .selectedBox {
            padding: 12px;
            background: $white;
            border-radius: 8px;
            color: $black;
            border: 1px solid $gray-500;

            &.checked {
                background: $primary;
                color: $white;
                border: 1px solid $primary;

                .form-check-label {
                    background-color: $white;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    @include dFlex;
                }

                .form-check-input {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: transparent;
                    margin-top: 0px;
                }
            }

            .form-check-input {
                border: 1.3px solid transparent;
            }
        }

        h4 {
            color: $dark;
            font-family: $fontPoppins;
            line-height: normal;

            @media(max-width:$md) {
                font-size: 28px !important;
                margin-bottom: 5px;
            }
        }

        .forgotPassword {
            h6 {
                color: $primary;
            }
        }

        .privacyPolicy {
            p {
                span {
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }

        .btn-deepgreen {
            border-radius: 8px;
        }

        .fields-wrapper {
            gap: 30px;
            margin-bottom: 20px;

            @media(max-width:$xxl) {
                gap: 20px;
                margin-bottom: 20px;
            }

            @media(max-width:$md) {
                gap: 5px;
            }

            .mail {
                label {
                    color: $dark;
                    line-height: normal;
                    margin-bottom: 12px;

                    @media(max-width:$md) {
                        margin-bottom: 4px;
                    }
                }

                .form-control {
                    padding: 18px;
                    border: 1px solid $gray-300;

                    @media(max-width:$xxl) {
                        padding: 12px 18px;
                    }

                    @media(max-width:$md) {
                        padding: 8px 18px;
                    }

                    &::placeholder {
                        color: $gray-500;
                    }

                    &:focus {
                        border: 1px solid $primary;
                    }
                }

                img {
                    position: absolute;
                    right: 14px;
                    top: 22px;

                    @media(max-width: $xxl) {
                        top: 14px;
                    }
                }

                .eyeicon {
                    top: 19px;

                    @media(max-width: $xxl) {
                        top: 14px;
                    }
                }
            }
        }

        .checkbox {
            .form-check-input[type=checkbox] {
                border-radius: .25em;
                width: 22px;
                height: 22px;
                margin-right: 12px;
                margin-top: 0px;
            }

            .form-check-input:checked {
                background-color: $primary;
                border-color: $primary;
                width: 22px;
                height: 22px;
            }

            label {
                color: $gray-800;
            }

            margin-bottom: 50px;

            @media(max-width:$xxl) {
                margin-bottom: 25px;
            }
        }

        input {
            &:active {
                border: 1px solid $primary !important;
            }

            &:-webkit-autofill:active {
                border: 1px solid $primary !important;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: $dark;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px $white;
        }

        input:-webkit-autofill::first-line,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            font-size: 1rem;
        }
    }

    // right side form css ends
}

// modal css starts
.modal-dialog {
    position: relative;
    width: auto;
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    pointer-events: none;

    .modal-body {
        .authentication-txt {
            margin-bottom: 24px;

            p {
                color: $gray-600;
            }

            h4 {
                color: $primary;
            }
        }

        .inputotp {
            div {
                gap: 13px;
                justify-content: center;

                input {
                    width: 73px !important;
                    height: 73px;
                    border: 1px solid $gray-500;
                    border-radius: 8px;
                    font-size: 28px;
                    font-weight: 500;

                    @media (max-width: $sm) {
                        width: 50px !important;
                        height: 50px;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        .mail {
            img {
                position: absolute;
                right: 14px;
                top: 16px;
            }
        }

        .recieveCode {
            gap: 3px !important;

            p {
                color: $gray-700;
            }

            button {
                background: transparent !important;
                border: none;
                width: auto;
                padding: 0;
                color: $primary;

                &:hover {
                    border: none;
                    padding: 0;
                    color: $primary;
                }
            }

        }


        .form-control {
            padding: 18px;

            @media(max-width: $sm) {
                padding: 15px 10px;
            }
        }

    }

    .border-modal-login {
        border-bottom: transparent !important;
    }

    .border-bottom-modal {
        margin-top: 28px;

        button {
            background-color: $primary;
            border: 1px solid $primary;
            width: 100%;

            &:hover {
                background-color: $white;
                border: 1px solid $primary;
                color: $primary;
            }
        }
    }
}

.modalLogin.modal {
    --bs-modal-width: 570px !important;
}