@import "./variable.module";

.dashboardScreen {
  height: calc(100vh - 88px);
  overflow-y: auto;
  margin-top: 88px;

  @media(max-width:$xxl) {
    height: calc(100vh - 99px);
    margin-top: 82px;
  }

  @media(max-width:$sm) {
    margin-top: 137px;
    height: calc(100vh - 135px);
  }
}

.spinner-border {
  --bs-spinner-border-width: 0.15em;
  --bs-spinner-animation-speed: 0.95s;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  border: var(--bs-spinner-border-width) solid #215154;
  border-right-color: transparent;
}

.mainsidebar.closeSidebar+.DashboardWrapper {
  @media (max-width:1250px) {
    width: calc(100% - 0px);
  }
}

.mainsidebar+.DashboardWrapper {
  @media (max-width:$md) {
    width: calc(100% - 0px);
  }
}

.DashboardWrapper {
  width: calc(100% - 250px);

  @media (max-width: $xxl) {
    width: calc(100% - 190px);
  }
}

.loaderBtn {
  .spinner-border {
    border: var(--bs-spinner-border-width) solid #ffffff;
    border-right-color: transparent;
  }
}

.cursor {
  cursor: pointer;
}

// date time show css
.lates-update {
  p {
    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $black;
    }
  }
}

.terms-condition-modal.modal-dialog {
  --bs-modal-width: 1150px !important;
  height: 100vh;

  .tems-condition-data {
    min-height: 300px;
    max-height: 450px;
    overflow-y: auto;
  
  }
  .tems-condition-data::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .tems-condition-data::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
  }
   
  /* Handle */
  .tems-condition-data::-webkit-scrollbar-thumb {
    background: $primary; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .tems-condition-data::-webkit-scrollbar-thumb:hover {
    background: $primary; 
  }
}

.form-control {
  &:focus {
    border-color: $gray-200;
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 12px;
  }
}

.graph-loader {
  height: 50vh;
}

.graph-loaderPie {
  height: 30vh;
}

.custom-form {
  .form-switch {
    padding-left: 0px;
  }
}

.highcharts-background {
  fill: $info;
}

.logo-icon-name {
  @include dFlex;
  width: 30px;
  height: 30px;
  background: $primary;
  color: $white;
  border-radius: 50%;
  text-transform: uppercase;
}

// back button
.backBtn {
  padding-block: 13px !important;

  .btn-deepgreen {
    span {
      img {
        transition: all 0.5s ease-out;
      }
    }

    &:hover {
      span {
        img {
          filter: invert(0%) sepia(3%) saturate(7500%) hue-rotate(217deg) brightness(0%) contrast(100%);
        }
      }
    }
  }

  &:hover {
    span {
      img {
        filter: invert(0%) sepia(3%) saturate(7500%) hue-rotate(217deg) brightness(0%) contrast(100%);
      }
    }
  }
}

.search-icon-img {
  position: relative;

  .dropdownSpinner {
    position: absolute;
    z-index: 9;
    top: 13px;
    left: 6px;

    @media(max-width:$lg) {
      top: 23px;
    }
  }

  span {
    img {
      position: absolute;
      z-index: 9;
      left: 8px;
      top: 31%;
      width: 15px;

      @media(max-width:$lg) {
        top: 44%;
      }
    }
  }

  .css-b62m3t-container,
  .css-3iigni-container {
    width: 270px;

    @media (max-width:$xxl) {
      width: 230px;
    }

    .css-13cymwt-control,
    .css-t3ipsp-control,
    .css-16xfy0z-control {
      .css-1fdsijx-ValueContainer,
      .css-hlgwow {
        padding-left: 25px !important;

        .css-1jqq78o-placeholder {
          font-size: 0.875rem !important;

          @media (max-width:$xxl) {
            font-size: 0.75rem !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {

  /* Your existing styles for media screens larger than 1600px */
  .col-xxxl-3 {
    grid-column: span 3 !important;
    /* Use with caution if needed for specificity */
    flex: 0 0 auto;
    width: 25%;
  }
}

// select dropdown
.select-box {

  .css-b62m3t-container,
  .css-3iigni-container {
    min-width: 137px;

    .css-13cymwt-control,
    .css-16xfy0z-control {
      min-height: 45px;

      .css-1xc3v61-indicatorContainer {
        padding-right: 3px !important;
        padding-left: 3px !important;
      }

      .css-1dimb5e-singleValue {
        text-overflow: inherit !important;
        text-transform: capitalize;
      }


    }

    .css-pogzpp-loadingIndicator,
    .css-o9ehiq-loadingIndicator {
      span {
        font-size: 4px !important;
      }
    }

    .css-15lsz6c-indicatorContainer {
      padding-right: 3px !important;
      padding-left: 3px !important;
    }


    .css-1u9des2-indicatorSeparator,
    .css-894a34-indicatorSeparator {
      display: none;
    }
  }

  .css-t3ipsp-control {
    min-height: 45px;
    border-color: $lightgreen !important;
    box-shadow: none;

    &:hover {
      border-color: $lightgreen !important;
      box-shadow: none !important;
    }
  }

  .paginationDropdown {
    max-width: 73px;
    min-width: 85px;

    div {
      text-align: left;
    }

    #react-select-5-listbox,
    #react-select-2-listbox {
      div {
        text-align: left;
      }
    }

    .css-13cymwt-control,
    .css-16xfy0z-control {
      min-height: 35px !important;
    }

    .css-t3ipsp-control {
      min-height: 35px !important;
    }
  }

  .yearDropdown,
  .quarterDropdown {
    min-width: 90px;
  }

  .custom-select {
    @media (max-width: $md) {
      margin-top: 6px;
    }

    .css-13cymwt-control {
      min-height: 45px;
    }

    .css-f85sjx-option:hover {
      background-color: $lightgreen !important;
      color: $primary !important;
    }

    .css-tr4s17-option {
      background-color: $primary !important;
      color: hsl(0, 0%, 100%) !important;
      padding: 8px 12px;
      box-sizing: border-box;
    }

    .css-d7l1ni-option {
      background-color: $lightgreen !important;
      color: $primary !important;
    }
  }

}

.form-select {
  font-size: 0.875rem;
  background-color: $gray-100;
  border: 1px solid $gray-500;
  padding: 11px 12px;
  min-width: 107px;
  width: auto;

  @media (max-width: $xxl) {
    padding: 6px 12px;
  }
}

// form css
form {
  .form-label {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid $gray-400;
    background: $white;
    padding: 12px 10px;
  }
}


a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint.gm-style-mtc-bbw {
  display: none;
}

.gm-style-cc {
  display: none !important;
}

.gm-svpc {
  display: none !important;
}
.userlist-carrierName {
  position: relative;

  .carrier-name-text {
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    &:hover {
      .text-hover {
        position: absolute;
        display: block;
        h5 {
          width: 200px;
          text-wrap: wrap;
          position: absolute;
          padding: 6px;
          background: $white;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .text-hover {
      position: absolute;
      display: none;
    }
  }

  .logo-icon-name-wrapper {
    min-width: 70px !important;
    max-width: 70px !important;
    padding: 0px !important;
    @include dFlex;

    .logo-icon-name {
      margin-left: 0px !important;
    }
  }

  img {
    max-width: 70px;
    min-width: 70px;
    height: 38px;
    object-fit: contain;
  }
}
.contactUS{
    --bs-modal-width: 680px !important;
  .modal-header{
    padding: 1rem !important;
  }
  .form-control{
    padding: 16px;
  }
}
.verticaldata {
  position: absolute;
  left: -9.4375rem;
  transform: rotate(360deg);
  top: 47%;
  overflow-y: auto;

  .linesbefore {
    width: 4.1875rem;
  }
}

.table-bg {
  .table {
    --bs-table-hover-color: rgba(193, 211, 192, 0.4);
    --bs-table-hover-bg: rgba(193, 211, 192, 0.4);
  }
}

.rgpGraph {
  button {
    &:hover {
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;
      cursor: auto;
    }
  }
}

.text-deepgreen {
  color: $primary;
}

.form-check-input {
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }

  border: 1.3px solid $primary;

  &:focus {
    border-color: $primary;
    outline: 0;
    box-shadow: none;
  }
}

.contact-field {
  position: relative;

  p {
    position: absolute;
    top: 47px;
    left: 9px;
    font-size: 1rem;
  }

  .form-control {
    padding: 10px 10px 10px 29px !important;
  }

}

// full page loader css
.overlayLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 20%);
  position: absolute;
  top: 0;
  z-index: 2000;
  left: 0;

  .spinner-border {
    --bs-spinner-border-width: 0.35em;
    --bs-spinner-width: 3.5rem;
    --bs-spinner-height: 3.5rem;
    border: var(--bs-spinner-border-width) solid $green;
    border-right-color: transparent;
  }
}

.primaryCard {
  height: 164px;
  padding: 0;
}

.titleMain {
  text-align: center;

  span {
    img {
      margin-bottom: 5px;

      @media (max-width: 1300px) {
        width: 29px;
        margin-bottom: 9px;
      }
    }
  }
}

.card-loader {
  height: auto;

  .spinner-border {
    --bs-spinner-border-width: 0.15em;
    --bs-spinner-animation-speed: 0.95s;
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
    border: var(--bs-spinner-border-width) solid #fff;
    border-right-color: transparent;
  }
}

.leftTitle {
  font-size: 1.25rem !important;
  font-weight: 600 !important;

  @media screen and (max-width: $xxl) {
    font-size: 0.875rem !important;
  }
}

.pagination .page-link {
  font-size: 0.75rem !important;
}

.btnHeight {
  height: 45px;
}

.pagination {
  flex-wrap: wrap;
}

.viewAll {
  background: transparent;
  border: none;
}

.form-control-error {
  border: 1px solid $danger
}

// action button css
.action-btn-dropdown {
  .dropdown-toggle {
    border: none !important;
    padding: 0px;

    &::after {
      display: none;
    }

    &.bg_circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: transparent;
    }

    &.bgAction_circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: $gray-300;
    }
  }
}

.action-btnlist {
  position: absolute;
  right: 19px;
  width: 190px;
  background: $white;
  padding: 12px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 5;
  display: none;

  button {
    padding: 8px 8px;
  }

  &.action-btnlistVisible {
    display: flex;
  }

  .gray-btn {
    background-color: $gray-100 !important;
    border: 1px solid $gray-100;

    &:hover {
      background: $gray-100 !important;
    }
  }
}

.text-deepgreen {
  color: $primary;
}