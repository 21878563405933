@import "../config/variable.module.scss";

.ck.ck-editor__top.ck-reset_all{
    display: none !important;
}
.ck-content{
    border-radius: 8px !important;
    border: 1px solid $gray-400 !important;
    padding: 18px !important;
    min-height: 120px;
    max-height: 120px;
    overflow-y: auto;
    p{
        margin: 0px !important;
    }
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
   box-shadow: none !important; 
}