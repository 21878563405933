@import "../config/variable.module.scss";

body {
    overflow-y: auto;
    overflow-x: hidden;
}

.signUp-wrapper {
    .main-screen-signup {
        align-items: center;

        @media(max-width:1400px) {
            align-items: inherit;
        }
    }

    .left-side-wrapper {
        @media(max-width:1400px) {
            height: 100% !important;
        }
    }

    .right-side-wrapper {
        width: 82%;
height: 100vh;
display: flex;
justify-content: center;
flex-direction: column;
        @media(max-width:1400px) {
            height: 100% !important;
        }

        .input-field-ui>div>div {
            margin-bottom: 0px !important;
        }

        .select-box {
            .custom-select {

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    min-height: 54px;

                    @media(max-width:1550px) {
                        min-height: 42px;
                    }

                }
            }
        }

        .normal-input {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            min-height: 83px;
            @media(max-width:1670px){
                min-height: 94px;
            }
        }

        .disabled-button {
            height: 47.8px;
        }

        .fields-wrapper {
            gap: 17px;

            .contact-field {
                p {
                    line-height: 18px;
                    top: 45px;
                    @media(max-width:1920px) {
                        top: 47px;
                    }
                    @media(max-width:1670px) {
                        top: 45px;
                    }
                    @media(max-width:1550px) {
                        top: 40px; 
                    }
                }
            }

            .mail {
                .form-control {
                    height: 54px;

                    @media(max-width:1550px) {
                        height: 42px;
                    }

                    padding: 0px 42px 0px 12px;
                }

                &.email {
                    img {
                        @media(max-width:1550px) {
                            top: 40px;
                        }

                        top: 46px;
                    }
                }

                img {
                    @media(max-width:1550px) {
                        top: 37px;
                    }

                    @media(max-width:1350px) {
                        width: 18px;
                    }

                    top: 43px;
                }

                label {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

    .dataManagement.registration-modal.modal-dialog {
        --bs-modal-width: 580px !important;
        .modal-header{
            padding-bottom: 0px !important;
        }
        .modal-body{
            h6{
                line-height: 24px
            }
        }
    }
