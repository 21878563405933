@import "../config/variable.module.scss";

.terms-condition-screen {
    &.faq-main-screen {
        padding: 22px 16px;

        .company-logo,
        .powereb-by {
            display: none;
        }

        .faq-banner { 
            .banner-container {
                padding: 0px 160px 0px 108px;
                min-height: 242px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media(max-width:1920px){
                    min-height: 200px;
                }
                @media(max-width:1700px){
                    padding: 0px 160px 0px 80px;
                }
                @media(max-width:1587px){
                    min-height: 165px;
                }
                @media(max-width:1300px){
                    min-height: 125px;
                }
            }
        }
    }
}