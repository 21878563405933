@import "../config/variable.module.scss";

.profile-screen {
    @include mainSectionBg;

    .form-control:disabled {
        height: 46px;
    }

    .createProfileCard {
        .carrier-logo-input {
            border: 1px dashed $gray-600;
            background-color: $gray-100;
            height: 100px;
            width: 300px;
            border-radius: 8px;
            @include dFlex;
            flex-direction: column;
            cursor: pointer;

            h6 {
                color: $gray-600;
            }
        }
    }

    .carrier-img-wrap {
        width: fit-content;
        border: 1px dashed $primary;
        border-radius: 8px;
        position: relative;
        padding: 10px;

        span {
            img {
                position: absolute;
                top: 3px;
                right: 6px;
                padding-right: 0px !important;
                width: 22px;
                cursor: pointer;
            }
        }

        .carrier-img {
            min-width: 120px;
            max-width: 120px;
            width: 120px;
            height: 64px;
            object-fit: contain;
        }
    }

    .uploadImageSection {

        .uploadImage {
            position: relative;
            border-radius: 50%;

            img {
                width: 150px;
                max-width: 150px;
                max-height: 150px;
                height: 150px;
                border-radius: 50%;
                padding-right: 0px !important;

                @media(max-width:1300px) {
                    width: 130px;
                    max-width: 130px;
                    max-height: 130px;
                    height: 130px;
                }
            }

            .imageupload {
                width: 30px;
                position: absolute;
                bottom: 11px;
                height: 30px;
                right: 3px;
                background-color: $primary;
                cursor: pointer;
                padding-right: 0px !important;
            }
        }
    }
}