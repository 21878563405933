@import "../../scss/config/variable.module";

.pagination {
    gap: 7px;
    margin-bottom: 0px !important;

    .page-link.active,
    .active>.page-link {
        z-index: 3;
        color: $white;
        background-color: $primary ;
        border-color: $primary;
        border-radius: 5px;
    }

    .page-link {
        color: $primary;
        border-radius: 5px;
        padding: 0px;
        width: 35px;
        height: 35px;
        @include dFlex;

        &:focus {
            box-shadow: none;
        }
    }
}