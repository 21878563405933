@import "../config/variable.module.scss";

.mainsidebar {
    background: $primary;
    min-height: 100vh;
    left: 0;
    width: 250px;
    transition: 0.5s;
    position: relative;

    &.closeSidebar {
        @media(max-width:1250px) {
            width: 0px;
        }
        .scope-wrapper{
            display: none;
        }
    }

    @media (max-width: $xxl) {
        width: 190px;
    }

    @media (max-width: $md) {
        width: 184px;
        position: absolute;
        z-index: 99;
    }

    .scope-wrapper {
        position: absolute;
        bottom: 15px;
        left: 24px;

        @media(max-width:$xxl) {
            left: 15px;
        }

        img {
            @media(max-width:1600px) {
                width: 130px;
            }
        }
    }

    .sidebarLogo {
        padding: 24px;

        @media(max-width:$xxl) {
            padding: 24px 0px 7px 18px;
        }

        position: relative;

        .closedIcon {
            display: none;

            @media(max-width:1250px) {
                display: block;
                padding-right: 0px !important;
                right: -11px;
                top: 24px;
                position: absolute;
                z-index: 99;
            }

            img {
                width: 25px;
            }
        }
    }

    .greensightLogo {
        width: 90%;
        transition: 0.5s;
        padding-right: 0px !important;

        @media (max-width: $xxl) {
            width: 80%;
        }

    }

    .sidebarnav-wrapper {
        max-height: 711px;
        overflow-y: auto;
        padding-top: 22px;
        overflow-x: hidden;

        @media (max-width:1600px) {
            max-height: 605px;
        }

        @media (max-width:1450px) {
            max-height: 680px;
        }

        @media (max-width:$xxl) {
            max-height: 513px;
        }

        @media (max-width:$xl) {
            max-height: 580px;
        }

        @media (max-width: 1100px) {
            max-height: 329px;
        }

        @media (max-width: $lg) {
            max-height: 257px;
        }
    }

    .toogleSwitch {
        .toggle {
            width: 145px !important;
            height: 40px !important;
            border-radius: 36px;
        }

        .toggle.btn {
            .toggle-group {
                top: 1px;

                .toggle-handle {
                    height: 26px;
                    margin-top: 5px;
                    border-radius: 76px;
                    margin-left: 43px;
                }
            }
        }

        .toggle>.toggle-group>.toggle-on,
        .toggle-off {
            font-size: 0.875rem;
            top: 2px;
        }

        .toggle.btn.btn-primary {
            background-color: $primary !important;
            border: 1px solid $primary;

            .toggle-group {
                .btn-primary {
                    background-color: $primary !important;
                }

                .toggle-handle {
                    height: 26px;
                    margin-top: 5px;
                    border-radius: 76px;
                    margin-right: 43px;
                    margin-left: 0px;
                }
            }
        }

    }

    .switch.btn {
        min-width: 149px;
        border-radius: 30px;
        max-width: 171px;
        margin-right: 0px;
        margin-left: 22px;

        .switch-handle {
            height: 26px;
            margin-top: 5px;
            border-radius: 76px;
            margin-right: 0px;
            margin-left: 37px;
        }

        .switch-on.btn.btn-primary,
        .switch-off.btn.btn-light {
            background-color: $primary;
            border: 1px solid $primary;
            color: $white;
            font-size: 0.875rem;
        }
    }

    .switch.btn.on.btn-primary {
        background-color: $primary;
        border: 1px solid $white;

        .switch-handle {
            margin-right: 34px;
            margin-left: 0px;
        }
    }

    .switch.btn.off.btn-light {
        .switch-handle {
            margin-right: 0px;
            margin-left: 37px;
        }
    }

    .accordion {
        --bs-accordion-bg: $primary;
        width: 250px;

        @media (max-width: $xxl) {
            width: 190px;
        }

        .nav {
            .accordion-item {
                border: none;
                border-radius: 0px;

                .accordion-header {
                    padding: 15px 16px 15px 24px;

                    @media(max-width: $xxl) {
                        padding: 12px 15px 12px 15px;
                    }

                    .accordion-button {
                        background: $primary;
                        border: 1px solid $primary;
                        color: $white;
                        border-radius: 0px 19px 19px 0px;
                        box-shadow: none;
                        padding: 0;

                        img {
                            padding-right: 0px !important;
                        }

                        &:not(.collapsed)::after {
                            background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                            right: 0px !important;
                            --bs-accordion-btn-icon-width: 1rem;
                            transform: rotate(0deg);

                            @media(max-width:$xxl) {
                                right: -10px !important;
                            }

                        }

                        &::after {
                            background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                            right: -1px !important;
                            --bs-accordion-btn-icon-width: 1rem;
                            transform: rotate(-90deg);

                            @media(max-width:$xxl) {
                                right: -10px !important;
                            }
                        }
                    }

                    z-index: 2;
                    position: relative;

                    .navitemtxtWrapper {
                        .navText {
                            font-weight: 400;
                            font-size: 1rem;

                            @media(max-width:$xxl) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                }

                // navbar active nav css accordian starts
                .activebar.accordion-header {
                    .accordion-button {
                        background: transparent;
                        border: 1px solid transparent;
                        color: $white;
                        border-radius: 0px 19px 19px 0px;
                        box-shadow: none;
                        padding: 0;

                        &:not(.collapsed)::after {
                            background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                        }

                        &::after {
                            background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                        }
                    }
                }

                // navbar active nav css accordian ends

                .accordion-button.collapsed {
                    margin-bottom: 10px;

                    @media (max-width:$xxl) {
                        margin-bottom: 6px;
                    }
                }

                .accordion-collapse {
                    border-radius: 0px;

                    .accordion-body {
                        background: $primary;
                        color: $white;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }
            }

            .newaccordian {
                width: 218px !important;

                @media (max-width: $xxl) {
                    width: 160px !important;
                }

                .accordion-item {
                    .accordion-header {
                        .accordion-button {
                            border: none;

                            &.collapsed {
                                margin-bottom: 0px;

                                &::after {
                                    background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                                    right: 14px !important;

                                    @media(max-width:$xxl) {
                                        right: 4px !important;

                                    }

                                }
                            }

                            &::after {
                                background-image: url('../../assets/images/sidebar/upWhiteAccordianArrow.svg');
                                right: 14px !important;

                                @media(max-width:$xxl) {
                                    right: 7px !important;
                                }
                            }
                        }

                        &:after {
                            position: absolute;
                            left: 200px;
                        }
                    }

                    .accordion-body {
                        .nav {
                            .nav-item {
                                .navitemtxtWrapper {
                                    @media(max-width:$xxl) {
                                        padding: 12px 15px 9px 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-item {
            a {
                text-decoration: none;
            }

            .navitemtxtWrapper {
                padding: 15px 16px 15px 24px;

                @media(max-width: $xxl) {
                    padding: 12px 15px 12px 15px;
                }

                .navText {
                    color: $white;
                    font-weight: 400;
                    font-size: 1rem;

                    @media(max-width:$xxl) {
                        font-size: 0.875rem;
                    }
                }

                z-index: 2;
                position: relative;

                img {
                    padding-right: 0px !important;
                }
            }

            .active {

                .navitemtxtWrapper {

                    .navTextChild {
                        font-weight: 400 !important;
                        text-decoration: underline;
                        text-decoration-color: $orange;
                        text-underline-offset: 4px;
                        text-decoration-thickness: 2px;
                    }
                }
            }

            .navTextChild {
                font-weight: 300 !important;
            }
        }

        // navbar active nav css starts
        .activebar {



            &.barPosition {
                &:before {
                    @media(max-width:$xxl) {
                        top: -34px;
                    }
                }
            }

            .navitemtxtWrapper {
                .navText {
                    text-decoration: underline;
                    text-decoration-color: $orange;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 2px;
                }

                position: relative;
            }

            .accordion-button {
                .navitemtxtWrapper {
                    .navText {
                        font-weight: 400 !important;
                    }
                }
            }
        }

        // navbar active nav css ends
    }

    &::-webkit-scrollbar-thumb {
        background: $green;
        height: 8px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $green;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $primary;
        border-radius: 10px;
        height: 8px;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
}