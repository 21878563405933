@import "../config/variable.module.scss";

.userManagement-screen {
  .line {
    width: 1px;
    background: $gray-300;
    height: 40px;
    margin-right: 12px;
  }

  .userManagement-Wraper {
    .userManagement-heading {
      h2 {
        font-size: 1.75rem;
      }
    }

    .userManagementUser {
      min-height: calc(100vh - 210px);
      @include mainSectionBg;

      .userManagementInnerCard>div {

        min-height: calc(100vh - 123px);

        @media(max-width:1360px) {
          min-height: calc(100vh - 156px);
        }
      }

      .userManagementInnerCard {
        .createUserCard {
          padding: 70px 20px;

          h3 {
            font-size: 1.5rem;
          }

          button {
            font-size: 0.875rem;
          }
        }

        .createUserCard {
          background-color: $gray-100;
        }
      }
    }

    .userManagementUser>.item {
      min-height: calc(100vh - 208px);
    }

    .editLink {
      button {
        height: 30px;
      }
    }

    .userManagement_List {
      min-height: 80vh;

      .btn-deepgreen {
        height: 33px;
      }

      .btnHeight {
        height: 48px;
      }

      .addUser-btn {
        height: 42px !important;
      }

      .infinite-scroll-component {
        overflow: hidden !important;
      }

      .activity::-webkit-scrollbar {
        display: none !important;
      }

      .activity {
        max-height: 300px;
        overflow: auto;
        padding-right: 10px;
        -ms-overflow-style: none !important;
        /* IE and Edge */
        scrollbar-width: none !important;
        /* Firefox */
      }

      @include mainSectionBg;
      padding: 16px;

      .bottomline {
        border-bottom: 1px solid $gray-200;

        .active {
          @include circle(10px);
          background-color: $success;
        }

        .deactive {
          @include circle(10px);
          background-color: $orange;
        }

        .inactive {
          @include circle(10px);
          background-color: $gray-600;
        }

        h4 {
          margin-bottom: 0px;
        }

        .backBtn {
          padding-block: 0px !important;
        }

        .select-box {
          .dangerBtn {
            height: 42px;
          }
        }

        .custom-select {
          &.selectStatus {
            .css-f85sjx-option:hover {
              background-color: $lightgreen !important;
              color: $primary !important;
            }

            .css-tr4s17-option {
              background-color: $white !important;
              color: $primary !important;
              padding: 8px 12px;
              box-sizing: border-box;
              font-weight: 600;
            }

            .css-d7l1ni-option {
              background-color: $lightgreen !important;
              color: $primary !important;
            }
          }

          .css-13cymwt-control,
          .css-t3ipsp-control {
            min-height: 42px;
          }

          .css-1xc3v61-indicatorContainer,
          .css-15lsz6c-indicatorContainer {
            padding: 5px;
          }


        }

      }

      .fileStatus,
      .personalDetails {
        border-top: 0 !important;
        border-radius: 0 0 8px 8px;
        word-break: break-all;

        .forms {
          margin-bottom: 0px !important;

          .form-label {
            margin-bottom: 6px;
          }
        }

        .contact-field {
          p {
            top: 41px;
          }
        }



        .name-div {
          width: 180px;

          @media(max-width:1300px) {
            width: 160px;
          }

          &.email {
            width: 240px;

            @media(max-width:1300px) {
              width: 180px;
            }
          }
        }
      }

      .btn-group {
        .advanceSearch {
          background: transparent;
          color: $black;
          border: transparent;
          font-weight: 500;
          font-size: 0.875rem;
        }

        .dropdown-menu {
          border-radius: 4px;

          &.show {
            transform: translate3d(-74px, 43.3333px, 0px) !important;
          }

          .form-control {
            height: 45px;
            border-radius: 4px;
          }

          .disabled-button {
            width: 100%;
          }

        }

        .custom-select {

          .css-13cymwt-control,
          .css-t3ipsp-control {
            min-height: 45px;
          }

          .css-1xc3v61-indicatorContainer,
          .css-15lsz6c-indicatorContainer {
            padding: 8px;
          }

        }

        .dropdown-item:hover,
        .dropdown-item:focus {
          color: var(--bs-dropdown-link-hover-color);
          background-color: $white;
        }
      }

      .userName {
        border-left: 1px solid $lightSilver;
        padding-left: 16px;
        margin-left: 8px;
      }

      .userDetail-outerCard {
        border-bottom: 1px solid $gray-200;
        border-radius: 8px 8px 0 0;
      }

      .activationLink {
        color: $primary;
      }

      .createProfileCard {
        margin-bottom: 14px;

        .support {
          color: $gray-600;
          margin-top: 12px;
        }

        .custom-file-upload {
          border: 1px solid $primary;
          color: $primary;
          display: inline-block;
          padding: 11px 24px 10px 24px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 0.875rem;
          font-weight: 500;
        }

        .custom-file-upload input[type="file"] {
          display: none;
        }

        .uploadImageSection {
          .uploadImage {
            img {
              width: 130px;
              height: 130px;
              border-radius: 4px;
              object-fit: contain;
            }
          }
        }
      }

      .searchWrapper {
        .search-carrier {
          .searchIcon {
            position: absolute;
            top: 13px;
            left: 10px;
          }

          .form-control {
            width: 270px;
            height: 42px;
            padding-left: 36px;
          }
        }

        .btn-deepgreen {
          padding: 10px 24px;
        }
      }

      .advanced_search {
        display: block;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
        position: absolute;
        z-index: 1;
        top: 50px;
        min-width: 421px;
        left: 0;

        input {
          width: 182px;
          font-size: 0.875rem;
        }

        label {
          font-size: 0.875rem;
        }
      }

      .static-table {
        tr {
          th {
            img {
              width: 17px;
            }
          }

          &:last-child:not(:first-child) {
            td {
              &:last-child:not(:first-child) {
                .action-btnlist {
                  bottom: 10px;
                }
              }
            }
          }
        }

        th:nth-child(2),
        td:nth-child(2) {
          width: 250px;
          word-break: break-word;

          @media(max-width:1504px) {
            width: 250px;
          }

          @media(max-width:1430px) {
            width: 190px;
          }
        }

        th:last-child,
        td:last-child {
          width: 65px;
        }

        td:first-child {
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        th:first-child,
        td:first-child {
          width: 310px;
          word-break: break-word;

          @media (max-width: 1550px) {
            width: 285px;
          }

          @media (max-width: 1470px) {
            width: 265px;
          }

          @media (max-width: $xxl) {
            width: 205px;
          }
        }

        .tWrap__head,
        .tWrap__body {
          max-width: 100%;
          width: 100%;

          @media (max-width: 1200px) {
            max-width: 1200px;
            width: 1000px;
          }
        }

        th {
          font-size: 0.875rem;
          color: $gray-700;
          vertical-align: baseline;
          padding: 5px;

          @media (max-width: $xxl) {
            font-size: 0.75rem;
          }
        }

        th,
        td {
          .form-check {
            padding: 0px;
            width: 12px;
            height: 12px;
            margin: 0px;

            .form-check-input {
              float: inherit;
              margin-left: 0;
              width: 12px;
              height: 12px;
              margin-top: 5px;
            }
          }
        }

        td {
          .active {
            @include circle(10px);
            background-color: $success;
          }

          .deactive {
            @include circle(10px);
            background-color: $orange;
          }

          .inactive {
            @include circle(10px);
            background-color: $gray-600;
          }

          .action-btn-dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: none;
              padding: 0px;

              &::after {
                display: none;
              }

              &.bg_circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: transparent;
              }

              &.bgAction_circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: $gray-300;
              }
            }
          }

          .action-btnlist {
            position: absolute;
            right: 19px;
            width: 190px;
            background: $white;
            padding: 12px;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            z-index: 5;
            display: none;

            button {
              padding: 8px 8px;
            }

            &.action-btnlistVisible {
              display: flex;
            }

            .gray-btn {
              &:hover {
                background: $gray-300 !important;
              }
            }

            .dangerBtn {
              img {
                filter: brightness(0) invert(1);
              }
            }

            .btn-deepgreen {
              img {
                filter: brightness(0) invert(1);
              }

              &:hover {
                img {
                  filter: brightness(0%);
                }
              }
            }
          }

          padding: 12px 5px;

          h6 {
            font-size: 0.875rem;
          }


        }
      }
    }
  }
}

.green-btn img {
  filter: brightness(0) invert(1);
}

.userDetail.modal {
  --bs-modal-width: 900px !important;

  .modal-dialog {
    height: 100vh;

    .modal-content {
      padding: 24px;

      .modal-header {
        .modal-title {
          font-size: 1.375rem;
        }

        border-bottom: none;
      }

      .contact-field {
        position: relative;

        p {
          position: absolute;
          top: 47px;
          left: 9px;
          font-size: 1rem;
        }

        .form-control {
          padding: 10px 10px 10px 29px !important;
        }

      }

      .modal-body {
        .updateProfile {
          img {
            width: 100px;
          }
        }

        .form-label {
          font-size: 1rem;
        }

        .form-select {
          padding: 10px;
        }

        .form-control {
          padding: 10px 10px;

          &:focus {
            border-color: $gray-500;
            outline: 0;
            box-shadow: none;
          }
        }

        .css-1u9des2-indicatorSeparator {
          display: none;
        }

        .css-13cymwt-control,
        .css-t3ipsp-control {
          border-radius: 4px;
          height: 46px;
          min-height: 34px;
          width: auto;
        }

        .modalbuttons {
          margin-top: 40px;
          margin-bottom: 40px;

          @media (max-width: $xxl) {
            margin-top: 30px;
            margin-bottom: 30px;
          }

          .cancelbtn {
            background-color: $gray-200;
            border: 1px solid $gray-200;
            color: $gray-700;
            padding: 11px 31px;
          }
        }
      }
    }
  }
}